@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.ged {
  width: 100%;
  padding: 20px;
  margin: auto;
  max-width: 1440px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  height: 100%;
  padding-bottom: 100px;
  overflow-y: auto;
  @media screen and (min-width: $md-screen) {
    padding: 30px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  &.desktop {
    @media screen and (min-width: $md-screen) {
      padding: 0px 0px 20px 0px;
      grid-template-columns: 1fr;
    }
    @media screen and (min-width: $lg-screen) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (min-width: $xl-screen) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  .card {
    padding: 15px;
    border: 1px solid rgba(163, 174, 208, 0.40);
    box-shadow: 0px 18px 32px rgba(208, 210, 218, 0.15);
    background-color: $white;
    border-radius: 10px;
    @include transition;
    cursor: pointer;
    &:hover {
      border: 1px solid $secondary-color;
    }
    .count {
      border-radius: 5px;
      background: #F4F7FE;
      font-family: 'Manrope-SemiBold';
      width: 30px;
      height: 30px;
      margin-bottom: 10px;
      @include flex-center;
    }
    h4 {
      font-size: 16px;
    }
    p {
      margin: 0px;
      font-size: 12px;
      color: $text-color-light;
    }
  }
}