@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';


.account {


  header { 
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%);

    .content {
      @include content();
      display: flex;
      align-items: center;

      h1 {
        margin: 0
      }
      .loader {
        position: relative;
        top: 4px;
        margin-left: $margin-xs;
      }
  
    }
  }

  >.content {
    @include content();
    min-height: 600px;

    background-image: url('../../assets/images/account-image.svg');
    background-repeat: no-repeat;
    background-position: 80% 10px;

    h4 {
      font-family: 'Manrope-Bold';
      margin-bottom: $margin-sm;
    }

    .form {
      background-color: $white;
      padding: $margin-md;
      max-width: 567px;
      width: 100%;
      border-radius: 8px;
      box-shadow: 0px 18px 32px 0px rgba(208, 210, 218, 0.15);

      .row {
        grid-gap: $margin-md;
    
        @media screen and (min-width: $sm-screen) {
          display: grid;
          grid-template-columns: 1fr 1fr;
        }
    
        .containerField {
          @include containerField();
        }

        .email {

          label {
            font-family: 'Manrope-SemiBold';
          }
          
          p {
            line-height: 45px;
            margin: 7px 0 0 0;
          }
        }
      
      }
  
    }

    .bottom {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      border-top: solid 1px $grey;
      padding-top: $margin-lg;
      .logout {
        span {
          text-decoration: underline;
          color: $primary-color;
        }
        svg {
          position: relative;
          top: 6px;
          margin-left: $margin-xs;
        }
      }
    }
    

  }

}