@import "../../styles/_variables.scss";

.tooltip {
  >div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: $grey;
    color: $white;
    font-size: 20px;
  }
}

.content {
  background-color: $white;
  padding: $margin-xs;
  border-radius: 5px;
  box-shadow: 0px 18px 32px 0px rgba(208, 210, 218, 0.15);
}