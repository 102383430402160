@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.field {
  .label {
    color:  #717A97;
    font-size: 14px;
    font-family: "Manrope-Regular";
    font-weight: 600;
    line-height: 140%;
  }
  p {
    font-family: "Manrope-SemiBold";
    margin-bottom: 0px;
  }
  &.discount {
    svg {
      color: #6AA187;
      margin-right: 2px;
      transform: translateY(3px);
      font-size: 16px;
    }
    p {
      color: #6AA187;
    }
  }
}