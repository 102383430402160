
@import "../../../../styles/_variables.scss";
@import "../../../../styles/_mixins.scss";

.form {
  position: relative;
  h3 {
    margin-bottom: $margin-md;
  }
  .submit {
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .fields {
    margin: $margin-lg 0 $margin-lg 0;
    background-color: $white;
    padding: 20px;
    max-width: 900px;
    .row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: $margin-md;
    }
    .containerField {
      @include containerField();
    }
  }
}