@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.summary {
  .row {
    display: flex;
    width: 100%;
    &:nth-child(even) {
      background-color: #F4F7FE;
      border-radius: 3px;
    }
    &.label {
      p {
        font-family: "Manrope-Bold";
      }
    }
    p {
      font-size: 12px;
      width: calc(50% /3);
      padding: 5px;
      margin: 0px;
      &:first-child {
        width: 50%;
      }
    }
  }
  .total {
    justify-content: flex-end;
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    .count {
      display: flex;
      justify-content: space-between;
      margin: 5px 0px;
      p {
        margin: 0px;
        font-size: 12px;
        width: 150px;
        text-align: right;
        &:first-child {
          width: 200px;
          text-align: left;
        }
        span {
          background-color: #FFF4B7;
          font-family: "Manrope-Bold";
          padding: 3px 7px;
          border-radius: 3px;
          margin-left: 10px;
        }
      }
    }
  }
}