@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.worksite {
  position: relative;
  height: 100%;
  width: 100%;
  .loader {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  header {
    position: relative;
    background-color: $white;
    .header-content {
      position: relative;
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 30px $margin-md;
      margin: auto;
      max-width: 1440px;
      .title {
        display: flex;
        align-items: flex-end;
        h2 {
          margin: 0px 10px;
        }
        p {
          margin: 5px 5px;
          color: $text-color-light;
        }
      }
      .nav {
        display: flex;
        position: absolute;
        right: 20px;
        bottom: 0px;
        nav {
          display: flex;
          align-items: flex-end;
          display: flex;
          margin-right: 20px;
          a {
            color: $text-color-light;
            height: 42px;
            padding: 0px 10px;
            margin: 0px 10px;
            &.active {
              border-bottom: 3px solid $primary-color;
              color: $secondary-color;
            }
          }
        }
        button {
          border-radius: 5px;
          padding: 15px;
          border: 1px solid #48507A;
          background: #FFF;
          color: $secondary-color;
          font-family: "Manrope-Medium";
          margin-bottom: 15px;
          &.download {
            display: flex;
            align-items: center;
            span {
              display: block;
              margin-top: 5px;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
  .content {
    position: relative;
  }
}