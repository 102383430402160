@import '~/styles/_variables.scss';
@import '~/styles/_mixins.scss';

.users {
    .header {
        display: flex;
        justify-content: space-between;
        align-content: center;
        margin-bottom: $margin-md;

        h4 {
            margin-bottom: 0;
        }

        .actions {
            button {
                margin-left: $margin-sm;
            }
        }

    }
}