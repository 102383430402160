@import "../../../../styles/_variables.scss";
@import "../../../../styles/_mixins.scss";

.modal {
  padding: 5px $margin-xs;
  h3 {
    font-size: 18px;
    text-align: center;
  }
  .field {
    margin-bottom: 20px;
  }
  .actions {
    display: flex;
    justify-content: space-between;
    .delete {
      padding: 0px;
      background-color: transparent;
      color: $warn;
      &:hover {
        text-decoration: underline;
      }
    }
    .cancel {
      background-color: transparent;
      color: $secondary-color;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.container .form {

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $margin-sm;
    h4 {
      margin-bottom: 0;
    }

    button {
      width: 40px;
      padding: 0;
      @include flex-center;
    }
  }

  .callbacks {
    .callback {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $white;
      border-radius: $radius;
      box-shadow: 0px 18px 32px 0px rgba(208, 210, 218, 0.15);
      padding: $margin-sm;
      margin-bottom: $margin-xs;
      .message {
        display: flex;
        align-items: center;
        .check {
          min-width: 16px;
          height: 16px;
          border: 1.5px solid rgba($secondary-color, 0.5);
          border-radius: 3px;
          margin-right: 10px;
          position: relative;
          cursor: pointer;
          svg {
            position: absolute;
            font-size: 16px;
            left: -1.5px;
            top: -1px;
          }
          &.active {
            color: transparent;
            border: 1.5px solid rgba($secondary-color, 0.5);
          }
          &:hover {
            color: rgba($secondary-color, 0.5);
          }
        }
        svg {
          font-size: 20px;
        }
        p {
          font-family: "Manrope-SemiBold";
          padding-left: 8px;
          width: 180px;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      p {
        margin: 0px;
        &.days {
          text-align: right;
          color: #717A97;
        }
      }
    }
  }
}