@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.home {
  .content {
    @include content();
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: $margin-md;

      h1 {
        margin-bottom: 0;
      }

    }

    .loader {
      width: 100%;
      height: calc(100vh - 190px);
      @include flex-center;
    }

    .stats {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: $margin-md;

      h4 {
        margin-bottom: $margin-md;
      }

      .statsEstimates {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: $margin-md;
        margin-bottom: 30px;
        .block {
          background-color: $white;
          border-radius: $radius;
          padding: $margin-sm;
          min-height: 96px;
          box-shadow: 0px 18px 32px 0px rgba(208, 210, 218, 0.15);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          p {
            margin: 0;
            font-size: 12px;
            &.count {
              margin-top: 10px;
              font-family: 'Manrope-Bold';
              font-size: $h1;
              line-height: $h1;
            }
          }
        }
      }

      .estimates {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: $margin-md;
        margin-bottom: 30px;
        .estimate {
          background-color: $white;
          border-radius: $radius;
          padding: $margin-sm;
          box-shadow: 0px 18px 32px 0px rgba(208, 210, 218, 0.15);
          border: 1px solid transparent;
          display: flex;
          flex-direction: column;
          @include transition;
          &:hover {
            border: 1px solid $text-color;
          }
          h2 {
            font-size: 18px;
            margin-bottom: 4px;
            line-height: 18px;
          }
          p {
            margin: 0px;
            margin-bottom: 4px;
            &:last-child {
              margin-bottom: 0px;
            }
          }
        }
        .callback {
          background-color: $white;
          border-radius: $radius;
          padding: $margin-sm;
          box-shadow: 0px 18px 32px 0px rgba(208, 210, 218, 0.15);
          border: 1px solid transparent;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          @include transition;
          &:hover {
            border: 1px solid $text-color;
          }
          h2 {
            font-size: 18px;
            margin-bottom: 4px;
            line-height: 18px;
          }
          p {
            margin: 0px;
            margin-bottom: 4px;
            &:last-child {
              margin-bottom: 0px;
            }
          }
          .status {
            text-align: right;
            p {
              color: $text-color-light;
            }
          }
        }
      }
    }
  }
}