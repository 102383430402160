@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.floorProtection {

  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $margin-md;
  }
  h4 {
    span {
      font-family: "Manrope-Regular";
    }
  }
  .containerField {
    @include containerField();
    &.checkbox {
      flex-direction: column;
      display: flex;
      justify-content: flex-end;
    }
  }
}
