@import '~/styles/_variables.scss';
@import '~/styles/_mixins.scss';

.detailClient {
  position: relative;
  min-height: 100%;
  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 50%;
    top: 0;
    bottom: 0;
    background-color: $white;
  }

  >.content {
    position: relative;
    z-index: 1;
    @include content();
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 100px;

    h4 {
      font-family: 'Manrope-Bold';
      font-size: 16px;
      margin-bottom: $margin-md;
    }

    >div:first-child {

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: $margin-md;
    
    
        .titlePage {
          display: flex;
          align-items: center;
          height: 70px;
          h1 {
            margin: 0;
          }
        }
    
        .delete {
          padding: 0;
          background-color: transparent;
          border: none;
          color: $warn;
        }
      }
    }

    >div:nth-child(2) {
      .estimates {
        margin-top: $margin-md;

        .estimatesHeader {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: $margin-sm;
          h4 {
            margin-bottom: 0;
          }
        }

        .estimatesList {
          .estimate {
            display: flex;
            align-items: flex-start;
      
            background-color: $white;
            border-radius: $radius;
            box-shadow: 0px 18px 32px 0px rgba(208, 210, 218, 0.15);
            padding: $margin-sm;
            margin-bottom: $margin-sm;

            cursor: pointer;

            h3 {
              font-size: $medium;
              margin: 0 0 $margin-xs 0;
            }

            p {
              margin: 0 0 3px 0;
              span {
                color: $text-color-light;
              }
            }
      
            >div:first-child {
              flex: 1;
              .text {
                margin-bottom: 5px;
              }
            }

            >div:nth-child(2) {
              .dot {
                position: relative;
                top: 1px;
                display: inline-block;
                width: 12px;
                height: 12px;
                border-radius: 6px;
                background-color: $primary-color;
                margin: 0 8px 0 0px;
              }
            }
      
          }
        }

      }
    }


  }

}