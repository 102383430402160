@import "../../../styles/_variables.scss";
@import "../../../styles/mixins";

.sidebar {
  .actions {
    margin-bottom: $margin-sm;
    transform: translateY(-4px);
    display: flex;
    button {
      font-size: 18px;
      padding: 0px 12px;
      font-size: 13px;
      &:first-child {
        margin-right: 10px;
      }
      svg {
        font-size: 18px;
        margin-right: 6px;
      }
      @include flex-center;
      &.copy {
        position: absolute;
        right: 0px;
        padding: 0px;
        width: 40px;
        svg {
          margin-right: 0px;
        }
      }
    }
  }
  ul {
    position: absolute;
    list-style: none;
    right: 0px;
    li {
      &.active {
        p {
          opacity: 1;
        }
      }
      a {
        text-align: right;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
        p {
          margin: 0px;
          font-family: 'Manrope-SemiBold';
          opacity: 0.5;
        }
      }
    }
  }
}