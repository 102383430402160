@import "../../styles/_variables.scss";

.inputSearch {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  background-color: $white;
  border-radius: $radius;
  padding: $margin-xs;
  box-shadow: 0px 18px 32px 0px rgba(208, 210, 218, 0.15);

  svg {
    fill: $primary-color;
  }

  input {
    font-family: 'Manrope-SemiBold';
    padding: 0;
    border: none;
    background-color: transparent;
    margin-right: $margin-xs;
    width: 256px;
    &::placeholder {
      font-family: 'Manrope-Regular';
      opacity: 1;
      color: $text-color-light;
    }
  }
}