@import "../../../styles/_variables.scss";
@import "../../../styles/mixins";

.header {
  background-color: $white;
  width: 100%;
  position: absolute;
  z-index: 1;
  .content {
    @include content();
    .title {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      h1 {
        margin: 0px;
        font-size: 28px;
      }
      h2 {
        margin: 0px;
        font-size: 14px;
        padding: 10px 0px 0px 10px ; 
        font-family: "Manrope-Regular";
        color: $secondary-color;
      }
      a {
        cursor: pointer;
        svg {
          font-size: 22px;
          transform: translateX(-5px);
        }
      }
    }
  }
}