@import '../../../styles/variables.scss';
@import '../form-mixin.scss';

.container-select{
  &.discount {
    svg {
      color: #6AA187;
      margin-right: 4px;
      transform: translateY(3px);
      font-size: 16px;
    }
  }
  label {
    @include label;
  }

  &.inline{
    display: flex;
    justify-content: space-between;
    align-items: center;
    label{
      margin-right: 10px;
    }
  }

  &:not(.inline){
    label+.select{
      margin-top: 10px;
    }
  }

  .select{
    width: 100%;
    // min-width: 200px;
  }

  .dot {
    position: relative;
    top: 2px;
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: $primary-color;
    margin: 0 8px 0 5px;
  }

}
