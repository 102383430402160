@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.crane {

  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $margin-md;
  }

  h4 {
    font-family: 'Manrope-SemiBold';
    font-weight: normal;
    font-size: 16px;
    margin-bottom: 10px;
    color: $text-color;
  }


  h5 {
    font-family: 'Manrope-SemiBold';
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 6px;
    color: $text-color;
  }

  .containerField {
    @include containerField();
    &.checkbox {
      flex-direction: column;
      display: flex;
      justify-content: flex-end;
    }
  }

  .count {
    display: grid;
    grid-template-columns: 0.25fr 0.5fr 1fr;
    p {
      margin-left: 0px;
      text-align: right;
    }
    span {
      background-color: #FFF4B7;
      font-family: "Manrope-Bold";
      padding: 3px 7px;
      border-radius: 3px;
      margin-left: 5px;
      &.negative {
        
      }
    }
  }
}
