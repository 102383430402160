@import '../../../styles/variables';

.emailresetpwd-form{
  max-width: 400px;
  .form{
    margin-bottom: $margin-lg;
    .container-field{
      margin: 0 0 15px 0;
    }
    .message-error{
      font-family: "Manrope-Regular";
      font-size: $small;
      margin-bottom: 0;
      color : $warn;
    }
    .message-succeed{
      font-family: "Manrope-Regular";
      color : $secondary-color;
      font-size: $small;
      margin-bottom: 0;
    }
  }

  .containerSubmit {
    a {
      display: block;
      position: relative;
      font-size: $small;
      color: $text-color;
      margin-top: $margin-sm;
      text-decoration: underline;
    }
  }

}


