@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.cart {

  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $margin-md;
    &.assembly {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  h5 {
    font-family: 'Manrope-SemiBold';
    font-weight: normal;
    font-size: 16px;
    margin-bottom: 10px;
    color: $text-color;
  }

  .containerField {
    @include containerField();
    &.checkbox {
      flex-direction: column;
      display: flex;
      justify-content: flex-end;
    }
  }
}
