@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.navbar {
  position: relative;
  display: flex;
  background-color: $white;
  width: 100%;
  justify-content: center;
  height: 64px;
  box-shadow: 0px 18px 32px 0px rgba(208, 210, 218, 0.15);
  z-index: 10;
  .logo {
    position: absolute;
    left: 0px;
    img {
      transform: translateX(-10px);
      width: 120px;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      list-style: none;
      height: 64px;
      padding: 0 25px;
      @media screen and (min-width: $xl-screen) {
        padding: 0 $margin-lg;
      }
      a {
        display: block;
        letter-spacing: 1%;
        font-family: "Manrope-SemiBold";
        &:not(.active) {
          color: $text-color-light;
        }
        &.active {
          &:after {
            content: '';
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 0;
            height: 3px;
            background-color: $primary-color;
          }
        }
        
      }
    }
  }

  .profile {
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    right: $margin-md;
    transform: translateY(-50%);

    .notifs-toggle {
      position: absolute;
      background-color: $secondary-color;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      color: $white;
      transform: translate(-50%, 50%);
      @include flex-center;
      @include transition;
      cursor: pointer;
      &:hover {
        transform: translate(-50%, 50%) scale(1.1);
      }
    }

    .user {
      display: flex;
      align-items: center;
      margin-right: $margin-sm;

      .initial {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $primary-color;
        border-radius: 50%;
        height: 33px;
        width: 33px;
        min-height: 33px;
        min-width: 33px;
        color: $white;
        font-family: 'Manrope-SemiBold';
        margin-right: $margin-xs;
      }

      p {
        margin: 0;
      }

    }

    button {
      position: relative;
      top: 2px;
      color: $primary-color;
    }
  }
  .notifs-container {
    position: fixed;
    z-index: 100;
    right: -400px;
    padding: 80px 50px 50px 50px;
    @include transition;
    &.isOpen {
      right: 0px;
    }
    .notifs {
      border-radius: 10px;
      background: $white;
      box-shadow: 0px 18px 32px 0px rgba(208, 210, 218, 0.55);
      width: 300px;
      .notif {
        margin: 0px 15px;
        padding: 15px 0px;
        &:not(:first-child){
          border-top: 1px solid rgba($secondary-color, 0.5);
        }
      
        label {
          color: rgba($secondary-color, 0.5)
        }
        p {
          margin: 0px;
          &:hover {
            text-decoration: underline;
          }
          span {
            font-family: "Manrope-SemiBold";
          }
        }
      }
    }
  }
}