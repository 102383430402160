@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.estimate {
  position: relative;
  height: 100%;
  width: 100%;
  .loader {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .content {
    position: relative;
    @include content();
    padding-top: 0 !important;
    display: grid;
    grid-template-columns: 350px 1fr;
    gap: $margin-sm;

    .sidebar {
      position: sticky;
      top: 0;
      padding-top: $margin-sm;

      @media screen and (min-width: $xs-screen) {
        padding-top: $margin-md;
      }
    
      @media screen and (min-width: $sm-screen) {
        padding-top: $margin-lg;
      }

      .back {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        margin-bottom: $margin-sm;
        a {
          color: $text-color-light;
        }
      }


      .updatedAt {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: $margin-sm;
        color: $text-color-light;
        svg {
          margin-right: 5px;
        }
        p {
          margin: 0;
          color: $text-color-light;

        }
      }

      .status {
        display: flex;
        align-items: center;
        
        label {
          font-family: 'Manrope-SemiBold';
          color: $text-color-light;
          margin-bottom: 0;
        }

        >div {
          flex: 1;
          margin-left: $margin-sm;
        }
      }


    }

    .containers {
      padding: $margin-sm 20px 30px 20px;

      @media screen and (min-width: $xs-screen) {
        padding-top: $margin-md;
      }
    
      @media screen and (min-width: $sm-screen) {
        padding-top: $margin-lg;
      }

      .navTabs {
        display: flex;
        flex-wrap: nowrap;
        position: relative;
  
        a {
          display: inline-block;
          padding: $margin-sm;
          border-radius: 0 8px 0 0;
          white-space: nowrap;
          overflow: hidden;
          font-size: 14px;
          text-overflow: ellipsis;
          z-index: 0;
          &:first-child {
            border-radius: 0px;
            z-index: 2;
          }
          &:not(:first-child) {
            padding: $margin-sm $margin-sm $margin-sm 23px;
            margin-left: -8px;
          }

          &:not(.add) {
            background-color: $white;
          }


          &.add {
            position: relative;
            top: 1px;
            border: dashed 1px #B8BDCB;
            border-bottom: none;
            height: 49px;
            display: flex;
            align-items: center;
            svg {
              margin-right: 4px;
              font-size: 20px;
            }
            cursor: pointer;
          }
        }

        .step {
          position: relative;
          border-bottom: none;
          border-radius: 0 8px 0 0;
          height: 49px;
          display: flex;
          align-items: center;
          width: 230px;
          z-index: 200;
          a {
            width: 100%;
            padding: 0px $margin-sm;
            height: 49px;
            background-color: #EAEDFA;
            border-radius: 0 8px 0 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &.active {
              background-color: $white;
            }
          }
          .icon {
            @include flex-center;
            svg {
              font-size: 18px;
              margin-right: 4px;
            }
          }
          cursor: pointer;
          .select-step {
            position: absolute;
            top: 0px;
            width: 230px;
            display: flex;
            z-index: 200;
            border-radius: 0px 8px 8px 8px;
            border: 1px solid rgba(113, 122, 151, 0.21);
            background: $white;
            box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.12);
            flex-direction: column;
            overflow: hidden;
            a {
              border-radius: 0px;
              margin-left: 0px;
              height: auto;
              background: $white;
              padding: 10px $margin-sm 10px $margin-sm;
              &:hover {
                background-color: #EAEDFA;
              }
            }   
          }
        }
      
  
        .add-step {
          .select {
            z-index: 200;
            position: absolute;
            border-radius: 8px;
            border: 1px solid rgba(113, 122, 151, 0.21);
            background: var(--Secondary-Primary-White, #FFF);
            box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.12);
            font-size: 14px;
            font-family: 'Manrope-Regular';
            padding-bottom: 10px;
            .label {
              height: 49px;
              height: 49px;
              display: flex;
              align-items: center;
              padding: $margin-sm;
              font-size: 14px;
              svg {
                margin-right: 4px;
              }
            }
            .option {
              padding: 4px $margin-sm;
              font-size: 12px;
              cursor: pointer;
              &:hover {
                text-decoration: underline;
              }
            }
          }      
        }

      }

      .tabs {
        position: relative;
        background-color: $white;
        padding: $margin-sm;
        z-index: 4;
        box-shadow: 0px 18px 32px rgba(208, 210, 218, 0.25)
      }
      .addPhase {
        float: right;
        border: 1px dashed #B8BDCB;
        border-radius: 4px;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        margin-top: 20px;
        cursor: pointer;
        @include transition;
        &:hover {
          background-color: rgba(#B8BDCB, 0.2);
        }
        svg {
          font-size: 18px;
          margin-right: 8px;
        }
      }
    }

  }
}