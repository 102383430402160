@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.nav {
  position: fixed;
  bottom: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
  nav {
    background-color: #1B2559;
    margin: auto;
    height: 50px;
    width: 291px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 34px;
      min-width: 36px;
      padding: 10px 6px;
      margin: 10px 8px;
      border-radius: 20px;
      transition: min-width 0.2s ease-in-out;
      overflow: hidden;
      svg {
        opacity: 0.8;
      }
      span {
        display: none;
        padding: 0px 6px;
        font-family: "Manrope-Bold";
        font-size: 13px;
      }
      &.active {
        background-color: $white;
        padding: 10px 6px;
        min-width: 96px;
        svg {
          margin: 0px 2px;
          opacity: 1;
        }
        span {
          display: block;
        }
      }
    }
  }
}