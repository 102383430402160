
@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.sidebar {

  hr {
    margin-bottom: $margin-sm;
  }
  .actions {
    margin-bottom: $margin-sm;
    transform: translateY(-4px);
    display: flex;
    button {
      font-size: 18px;
      padding: 0px 12px;
      font-size: 13px;
      &:first-child {
        margin-right: 10px;
      }
      svg {
        font-size: 18px;
        margin-right: 6px;
      }
      @include flex-center;
      &.copy {
        position: absolute;
        right: 0px;
        padding: 0px;
        width: 40px;
        svg {
          margin-right: 0px;
        }
      }
    }
  }

  .infos {
    p {
      margin: 0;
      color: $text-color-light;
      span {
        color: $text-color;
      }
    }
  }

  .notes {
    h4 {
      font-family: 'Manrope-SemiBold';
      font-size: $regular;
      color: $text-color-light;
    }
  }
  .history {
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
      h4 {
        font-family: 'Manrope-SemiBold';
        font-size: $regular;
        color: $text-color-light;
      }
      button {
        font-size: 13px;
      }
    }
    a {
      position: relative;
      display: flex;
      justify-content: space-between;
      border-radius: 8px;
      background: rgba(184, 189, 203, 0.20);
      box-shadow: 0px 18px 32px 0px rgba(208, 210, 218, 0.15);
      padding: 10px 10px 10px 15px;
      margin-bottom: 10px;
      &.active {
        background: $white;
        p {
          color: $text-color;
        }
      }
      .puce {
        position: absolute;
        width: 10px;
        height: 10px;
        top: 14px;
        left: -6px;
        border-radius: 10px;
      }
      p {
        font-size:13px;
        color: $text-color-light;
        margin: 0px;
      }
    }
  }
}