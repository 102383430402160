@import "../../styles/_variables.scss";

.layout {
  height: 100%;
  background-color: $bg-color;
  .navBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    padding: 0px 20px;
    background-color: $white;
    position: relative;
    @media screen and (min-width: $md-screen) {
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
      box-shadow: 0px 18px 32px 0px rgba(208, 210, 218, 0.15);
      z-index: 100;
    }
    .mobile {
      @media screen and (min-width: $md-screen) {
        display: none;
      }
      h1 {
        margin: 0px;
        font-size: 18px;
        text-transform: uppercase;
      }
      .back {
        display: flex;
        align-items: center;
        svg {
          font-size: 26px;
        }
        p {
          margin: 0px 6px;
          font-family: 'Manrope-Bold';
        }
      }
    }
    .desktop {
      display: none;
      @media screen and (min-width: $md-screen) {
        display: flex;
        align-items: center;
        img {
          width: 100px;
          transform: translateX(-25px);
        }
        h1 {
          margin: 0px;
          font-size: 14px;
        }
      }
    }
    .user {
      display: flex;
      align-items: center;
      p {
        display: none;
        @media screen and (min-width: $md-screen) {
          display: block;
          margin-bottom: 0px;
          margin-left: 10px;
          font-size: 14px;
        }
      }
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        .initial {
          background-color: $text-color;
          width: 38px;
          height: 38px;
          border-radius: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          @media screen and (min-width: $md-screen) {
            background-color: $primary-color;
          }
          span {
            color: $white;
            font-size: 15px;
          }
        }
      }
      button {
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        margin-left: 10px;
        display: none;
        @media screen and (min-width: $md-screen) {
          display: flex;
        }
        svg {
          color: $primary-color;
          font-size: 20px;
        }
      }
    }
  }

  .global {
    min-height: calc(100% - 64px);
    height: calc(100% - 64px);
    overflow-y: auto;
    background-color: #F4F7FE;
  }

}