@import "../../../../styles/_variables.scss";
@import "../../../../styles/_mixins.scss";

.form {
  height: 100%;
  @media screen and (min-width: $md-screen) {
    margin-bottom: 80px;
  }
  @media screen and (min-width: $lg-screen) {
    max-width: 60%;
    position: relative;
    z-index: 2;
  }
  .fields {
    @media screen and (min-width: $md-screen) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0px 20px;
    }
  }
  .submit {
    margin-top: 10px;
    width: 100%;
    display: block;
    @media screen and (min-width: $md-screen) {
      width: auto;
      float: right;
      margin-bottom: 30px;
    }
  }
  .section {
    margin-bottom: 30px;
    hr {
      margin-top: 30px;
    }
    h3 {
      font-size: 16px;
    }
    .reset {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .check {
        border: 1px solid #B8BDCB;
        width: 16px;
        height: 16px;
        border-radius: 3px;
        margin-right: 8px;
      }
      p {
        margin: 0px;
      }
    }
    &.col-3 {
      .fields {
        @media screen and (min-width: $xl-screen) {
          grid-template-columns: 1fr 1fr 1fr;
        }
      }
    }
    &.col-1 {
      .fields {
        grid-template-columns: 1fr;
      }
    }
    @media screen and (min-width: $md-screen) {
      margin-bottom: 20px;
      hr {
        margin-top: 20px;
      }
      h3 {
        font-size: 16px;
        margin-bottom: 20px;
      }
    }
  }
  .material {
    .add {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      width: 100%;
      svg {
        font-size: 20px;
        margin-right: 8px;
      }
      @media screen and (min-width: $md-screen) {
        display: none;
      }
    }
    .quantities {
      margin-bottom: 20px;
      display: grid;
      gap: 10px;
      @media screen and (min-width: $sm-screen) {
        grid-template-columns: 1fr 1fr;
        gap: 20px;
      }
      button {
        display: flex;
        justify-content: space-between;
        width: 100%;
        border-radius: 8px;
        background-color: $white;
        box-shadow: 0px 18px 32px 0px rgba(208, 210, 218, 0.15);
        padding: 15px;
        height: auto;
        p {
          margin: 0px;
          font-family: "Manrope-Bold";
          text-align: left;
          span {
            font-family: "Manrope-Regular";
            color: $text-color-light;
            font-size: 12px;
          }
        }
      }
    }
  }
}