@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.home {
  width: 100%;
  header {
    @media screen and (min-width: $md-screen) {
      background-color: $white;
      width: 100%;
    }
    .header-content {
      @include content();
      a {
        display: none;
      }
      h1 {
        font-size: 18px;
        margin-bottom: 0px;
      }
      p {
        margin-bottom: 0px;
        &.agency {
          font-family: "Manrope-SemiBold";
          margin-bottom: 10px;
        }
      }
      @media screen and (min-width: $md-screen) {
        display: flex;
        align-items: center;
        h1 {
          font-size: 28px;
        }
        a {
          display: block;
          cursor: pointer;
          svg {
            font-size: 22px;
            transform: translateX(-5px);
          }
        }
        p {
          display: none;
        }
      }
    }
  }
  .content {
    @include content();
    h2 {
      font-size: 14px;
      padding-bottom: 8px;
      border-bottom: 1px solid #B8BDCB;
    }
    .documents {
      display: grid;
      gap: 20px;
      padding-bottom: 20px;
      @media screen and (min-width: $md-screen) {
        grid-template-columns: 1fr 1fr 1fr;
      }
      @media screen and (min-width: $xl-screen) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
      a {
        display: block;
        position: relative;
        cursor: pointer;
        border-radius: 8px;
        background: $white;
        box-shadow: 0px 18px 32px 0px rgba(208, 210, 218, 0.15);
        @include transition;
        &:hover {
          box-shadow: 0px 18px 32px 0px rgba(208, 210, 218, 0.5);
        }
        padding: 15px 15px;
        h3 {
          margin-bottom: 2px;
          font-size: 14px;
        }
        p {
          color: #717A97;
          margin: 0px; 
        } 
        svg {
          position: absolute;
          right: 20px;
          top: 22px;
          font-size: 24px;
        }
      }
    }
    @media screen and (min-width: $md-screen) {
      margin-top: 40px;
      h2 {
        font-size: 16px;
        border-bottom: none;
      }
    }
  }
  .logout {
    position: absolute;
    bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    button {
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $primary-color;
      svg {
        margin-left: 10px;
      }
    }
    @media screen and (min-width: $md-screen) {
      display: none; 
    }
  }
}