
@import "../../../../styles/_variables.scss";
@import "../../../../styles/_mixins.scss";

.form {
  box-shadow: 0px 18px 32px rgba(208, 210, 218, 0.15);
  background-color: $white;
  padding: 20px 30px;
  border-radius: 8px;
  h3 {
    text-align: center;
    margin-bottom: $margin-md;
  }

  .containerField {
    @include containerField();
    &.name {
      input {
        padding: 0 0 5px 0;
        background-color: transparent;
        font-size: 16px;
        border: none;
        border-radius: 0;
        border-bottom: solid 1px $border-grey;
      }
    }
  }

  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .fitters {
    margin-bottom: 20px;
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .add {

      }
    }
    .list {
      margin-top: 15px;
      .fitter {
        @include transition;
        border-radius: 8px;
        border: 1px solid rgba(163, 174, 208, 0.40);
        box-shadow: 0px 18px 32px rgba(208, 210, 218, 0.15);
        padding:  12px 15px;
        margin-bottom: 10px;
        cursor: pointer;
        &:hover {
          border: 1px solid $secondary-color;
        }
        .title {
          display: flex;
          justify-content: space-between;
        }
        h4 {
          font-size: 16px;
          margin: 0px;
        }
        p {
          margin: 0px;
          font-size: 13px;
          color: $text-color-light;
        }
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    .delete {
      padding: 0px;
      background-color: transparent;
      color: $warn;
      &:hover {
        text-decoration: underline;
      }
    }
    button:first-child {
 
    }

    button:last-child {
      margin-left: $margin-md;
    }
  }
}