@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.worksiteDesktop {
  width: 100%;
  position: relative;
  .content {
    @include content();
    display: flex;
    flex-direction: column;
    @media screen and (min-width: $lg-screen) {
      flex-direction: row;
      justify-content: space-between;
    }
    .col {
      position: relative;
      @media screen and (min-width: $lg-screen) {
        &:nth-child(1) {
          width: 280px;
        }
        &:nth-child(2) {
          width: calc(100% - 660px);
        }
        &:nth-child(3) {
          width: 300px;
        }
      }
      h3 {
        font-size: 16px;
      }
      .new {
        position: absolute;
        right: 0px;
        top: -10px;
        display: flex;
        align-items: center;
        svg {
          font-size: 20px;
          margin-right: 8px;
        }
        @media screen and (min-width: $lg-screen) {
          position: relative;
          width: 100%;
          justify-content: center;
          top: 0px;
          margin-bottom: 20px;
        }
      }
      .requests {
        display: grid;
        gap: 20px;
        grid-template-columns: 1fr  1fr 1fr;
        margin-bottom: 30px;
        @media screen and (min-width: $lg-screen) {
          gap: 10px;
          grid-template-columns: 1fr;
        }
        button {
          display: flex;
          align-items: center;
          background-color: $white;
          text-align: left;
          border-radius: 8px;
          border: 1px solid rgba(163, 174, 208, 0.40);
          padding: 10px 20px;
          height: auto;
          p {
            margin: 0px;
          }
          .infos {
            display: block;
            margin-left: 20px;
            p {
               &:first-child {
                font-family: "Manrope-Bold";
               }
               &:last-child {
                color: #717A97;
                font-size: 12px;
               }
            }
          }
        }
      }
    }
  }
}

.modal {
  h3 {
    text-align: center;
    font-size: 18px;
  }
  .options {
    display: flex;
    flex-direction: column;
    a {
      border-radius: 8px;
      border: 1px solid $secondary-color;
      height: 70px;
      background-color: $white;
      box-shadow: 0px 18px 32px 0px rgba(208, 210, 218, 0.15);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      svg {
        margin-right: 30px;
      }
      p {
        margin: 0px;
        width: 200px;
        font-size: 16px;
        font-family: "Manrope-Bold";
        text-align: left;
      }
    }
  }
}