@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.request {
  padding: 20px 20px 90px 20px;
  @media screen and (min-width: $md-screen) {
    @include content;
    padding: 0px 20px 90px 20px;
  }
  h1 {
    font-size: 18px;
    margin-bottom: 0px;
    @media screen and (min-width: $md-screen) {
      display: none;
    }
  }
  h2 {
    font-size: 14px;
    @media screen and (min-width: $md-screen) {
      font-size: 18px;
    }
  }
}
.illu {
  display: none;
  @media screen and (min-width: $lg-screen) {
    display: block;
    position: absolute;
    z-index: 1;
    top: 60px;
    right: 0px;
    background-image: url('../../assets/images/form-image.svg');
    background-position: right -260px top;
    background-repeat: no-repeat;
    width: 50%;
    height: 80vh;
  }
  @media screen and (min-width: 1500px) {
    background-position: right -120px top;
  }
}