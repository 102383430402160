@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.agency {

  header { 
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%);

    .content {
      @include content();
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media screen and (min-width: $xs-screen) {
        padding: $margin-md 0 0 $margin-md;
      }
    
      @media screen and (min-width: $sm-screen) {
        padding: $margin-md 0 0 $margin-lg;
      }
  
      .titlePage {
        display: flex;
        align-items: center;

        >button {
          position: relative;
          top: -5px;
        }

        .agency {
          display: flex;
          align-items: center;
          height: 70px;

          h1 {
            position: relative;
            top: -5px;
          }
    
          h1, p {
            margin-bottom: 0;
          }
    
          p {
            color: $text-color-light;
            line-height: 27px;
            margin-left: $margin-sm;
          }
        }
      }
  
      nav {
        ul {
          margin: 0;
          padding: 0;
          li {
            position: relative;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            list-style: none;
            height: 70px;
            padding: 0 $margin-lg;
            a {
              display: block;
              letter-spacing: 1%;
              &.active {
                &:after {
                  content: '';
                  position: absolute;
                  width: 100%;
                  left: 0;
                  bottom: 0;
                  height: 3px;
                  background-color: $primary-color;
                }
              }
              
            }
          }
        }
      
      }
  
    }
  }

  >.content {
    @include content();
  }
}