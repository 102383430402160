
@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.modal {

  h3 {
    text-align: center;
  }

  label {
    display: block;
    margin-bottom: $margin-xs;
  }

  .containerField {
    @include containerField();
  }

  .file {
    display: inline-flex;
    align-items: center;
    margin-bottom: $margin-md;
    p {
      margin: 0;
    }
    button {
      position: relative;
      top: 1px;
      height: 22px;
      margin-left: 5px;
      color: $warn;
    }
  }

  .inputFile {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    label {
      margin: 0 $margin-xs 0 0;
    }

    button {
      padding: 0;
      width: 40px;

      svg {
        position: relative;
        top: 2px;
      }
    }

  }

  .buttons {
    display: flex;
    justify-content: flex-end;

    button:first-child {
      text-decoration: underline;
    }

    button:last-child {
      margin-left: $margin-md;
    }
  }
}