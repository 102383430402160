@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.estimates {
  .content {
    @include content();
    .filters {
      display: flex;
      align-items: center;
      margin-bottom: $margin-md;
      .selects {
        display: flex;
        align-items: center;
        margin-right: auto;
        .field {
          margin-right: $margin-sm;
        }
      }

      button {
        margin-left: $margin-sm
      }

    }
  }
  h4 {
    font-size: 14px;
    margin-bottom: 15px;
    margin-left: 5px;
  }
  .validations {
    margin-bottom: 30px;
  }
}