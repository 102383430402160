@import "../../styles/_variables.scss";

.field {
  margin-bottom: 15px;
  label {
    font-family: "Manrope-Bold";
    display: block;
    margin-bottom: 5px;
  }
  &.col-3 {
    @media screen and (min-width: $sm-screen) {
      width: calc(33% - 20px);
    }
  }
  .boolean {
    button {
      background-color: $white;
      color: $text-color;
      margin-right: 10px;
      height: 45px;
      &.isSelected {
        background-color: $text-color;
        color: $white;
      }
    }
  }
}