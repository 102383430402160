
@import "../../../../styles/_variables.scss";
@import "../../../../styles/_mixins.scss";

.form {

  h3 {
    text-align: center;
    margin-bottom: $margin-md;
  }

  .fields {
    margin: $margin-lg 0 $margin-lg 0;
    .containerField {
      @include containerField();
    }
  }

  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    .delete {
      padding: 0px;
      background-color: transparent;
      color: $warn;
      &:hover {
        text-decoration: underline;
      }
    }
    button:first-child {
      text-decoration: underline;
    }

    button:last-child {
      margin-left: $margin-md;
    }
  }
}