@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.container .form {

  section {
    margin-bottom: $margin-md;
  } 

  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $margin-sm;
    .containerField {
      @include containerField();
    }
  }
}