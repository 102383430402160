@import "../../../../styles/_variables.scss";

.dropInputFile {
  width: 83px;
  height: 83px;
  border-radius: 50%;
  .actions {
    display: flex;
    margin-top: $margin-sm;
    button {
      margin-left: auto;
      background-color: $white;
      color: $secondary-color;
      padding: $margin-sm;
    }
  }

}

.dropInputFile .container {
  width: 83px;
  height: 83px;
  border-radius: 50%;
  text-align: center;
  position: relative;
  border-radius: 50%;

  &.withPreview label {
    button, p {
      opacity: 0;
    } 
  }

  input {
    display: none;
  }

  label {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: $white;
    cursor: pointer;

    &.dragActive {
      border-color: $black;
    }    
  }

  .dragFile {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  label button {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 1rem;
    border: none;
    font-family: 'Oswald', sans-serif;
    background-color: transparent;
    &:hover {
      text-decoration-line: underline;
    }
  }

  .preview {
    position: absolute;
    z-index: 0;
    inset: 0;
    .containerImg {
      width: 83px;
      height: 83px;
      border-radius: 50%;
      background-color: $light-grey;
      overflow: hidden;
      img {
        pointer-events: none;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  
    .delete {
      position: absolute;
      z-index: 1;
      right: -4px;
      bottom: -7px;
    }
  }



}




