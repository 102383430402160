@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.winches {

  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $margin-md;
  }

  .containerField {
    @include containerField();
    &.checkbox {
      flex-direction: column;
      display: flex;
      justify-content: flex-end;
    }
  }
}
