@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $margin-sm;
}

.container form {
  position: relative;
  background-color: $white;
  padding: $margin-md;
  box-shadow: 0px 18px 32px 0px rgba(208, 210, 218, 0.15);
  border-radius: $radius;

  
  .roles {
    position: absolute;
    right: $margin-md;
    width: 200px;
    &.disabled {
      opacity: 0.3;
    }
  }

  .inputPicture {
    margin-bottom: $margin-md;
  }

  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $margin-sm;
    .containerField {
      @include containerField();
    }
  }
}