@import '~/styles/_variables.scss';
@import '~/styles/_mixins.scss';

.content {
    @include content();
}

.listUsers {
  .header {
    margin-bottom: $margin-md;
    .filters {
      width: 100%;
      display: flex;
      .field {
          margin-right: $margin-sm;
      }
    }

    .search {
        margin-left: auto;
        button {
          margin-left: $margin-sm;
        }
    }
}
}