@import '~/styles/_variables.scss';
@import '~/styles/_mixins.scss';

.detailUser {
  .header {
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%);
    
    .content {
      @include content();
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media screen and (min-width: $xs-screen) {
        padding: $margin-md $margin-md 0 $margin-md;
      }
    
      @media screen and (min-width: $sm-screen) {
        padding: $margin-md $margin-lg 0 $margin-lg;
      }

      .titlePage {
        display: flex;
        align-items: center;
        height: 70px;
        h1 {
          margin: 0;
        }
      }

      .delete {
        padding: 0;
        background-color: transparent;
        border: none;
        color: $warn;
      }
    }
  }

  >.content {
    @include content();
  }

}