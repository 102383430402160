@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.panel {
  position: fixed;
  z-index: 10;
  top: 0px;
  right: -100%;
  background-color: $text-color;
  height: 100vh;
  width: 100vw;
  @include transition;
  overflow: hidden;
  @media screen and (min-width: $xl-screen) {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 45%;
    height: calc(100vh - 164px);
  }
  @media screen and (min-width: $xl-screen) {
    width: 45%;
  }
  &.open {
    right: 0px;
  }
  .close {
    background-color: transparent;
    display: flex;
    align-items: center;
    padding: 30px 20px;
    svg {
      font-size: 20px;
      margin-right: 8px;
    }
    @media screen and (min-width: $xl-screen) {
      display: none;
      &.isSelected {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 40px 60px 0px;
      }
    }
  }
  .search {
    padding: 20px 20px;
    position: relative;
    svg {
      position: absolute;
      top: 30px;
      left: 28px;
      font-size: 24px;
      color: $text-color-light;
    }
    input {
      width: 100%;
      padding: 12px 20px 12px 40px;
      font-family: "Manrope-Regular";
      border: none;
      font-size: 14px;
      border-radius: 5px;
      &:focus {
        outline: none;
      }
    }
    @media screen and (min-width: $lg-screen) {
      padding: 40px 60px;
      svg {
        left: 68px;
        top: 50px;
      }
    }
  }
  .options {
    display: flex;
    flex-direction: column;
    padding: 0px 20px 40px 20px;
    max-height: calc(100vh - 145px);
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      background: rgba($white,0.2);
    }
    &::-webkit-scrollbar-thumb {
      background: rgba($white,0.2);
    }
    &::-webkit-scrollbar-thumb:hover {
      background: $white;
    }
    @media screen and (min-width: $lg-screen) {
      max-height: calc(100vh - 250px);
      padding: 0px 60px 40px 60px;
    }
    button {
      text-align: left;
      padding: 10px 0px;
      background-color: transparent;
    }
  }
  .selected {
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 80px);
    @media screen and (min-width: $lg-screen) {
      height: calc(100% - 40px);
      padding: 40px 60px;
    }
    .quantity {
      h2 {
        color: $white;
        font-size: 18px;
      }
      label {
        color: $white;
        font-family: "Manrope-Regular";
      }
      .input {
        display: flex;
        justify-content: space-between;
        margin: 10px 0px;
        background-color: $white;
        border-radius: 5px;
        padding: 5px;
        button {
          @include flex-center;
          background-color: transparent;
          color: $text-color;
          font-size: 26px;
          padding: 0px;
          width: 50px;
          &:hover {
            background-color: rgba($secondary-color,0.2);
          }
        }
        input {
          width: 100%;
          text-align: center;
          border: none;
          font-size: 18px;
          background-color: transparent;
          &::-webkit-inner-spin-button, 
          &::-webkit-outer-spin-button { 
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              margin: 0; 
          }
          &:focus {
            outline: none;
          }
        }
      }
    }
    .actions {
      button {
        width: 100%;
        background-color: $white;
        color: $text-color;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Manrope-SemiBold";
        svg {
          font-size: 20px;
          margin-right: 6px;
        }
        &.delete {
          background-color:  #FC8E8F;
          color: $white;
          margin-bottom: 10px;
        }
      }
    }
  }
}