@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.rental{

  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $margin-md;
    .price {
      span {
        background-color: #FFF4B7;
        font-family: "Manrope-Bold";
        padding: 3px 7px;
        border-radius: 3px;
      }
      &.discount {
        span {
          &:first-child {
            background-color: transparent;
            text-decoration-line: line-through;
          }
          background-color: rgba($success, 0.3);
        }
      }
    }
  }

  .containerField {
    @include containerField();
    &.checkbox {
      flex-direction: column;
      display: flex;
      justify-content: flex-end;
    }
  }
}
