@import "../../../../styles/_variables.scss";
@import "../../../../styles/_mixins.scss";

.memberForm {
  box-shadow: 0px 18px 32px 0px rgba(208, 210, 218, 0.15);
  border-radius: $radius;
  background-color: $white;
  padding: $margin-md;
  width: 100%;

  .header {
    margin-bottom: $margin-sm;
    display: flex;
    align-items: center;
    h4 {
      margin-bottom: 0;
    }

    button {
      margin-left: auto;
    }

  }

  .documents {
    .document {
      display: flex;
      margin-bottom: $margin-xs;
      border-bottom: solid 1px $grey;
      padding-bottom: $margin-sm;
      margin-bottom: $margin-sm;

      label {
        display: inline-block;
        width: 100px;
        margin: 0;
      } 

      .files {
        .file {
          display: flex;
          align-items: flex-end;
          p {
            margin: 0;
            &.fileName {
              color: $primary-color;
            }
          }
          .delete {
            position: relative;
            display: none;
            top: 2px;
            color: $text-color;
            cursor: pointer;
          }

          &:hover .delete{
            display: block;
          }

        }
        
      }
    }
  }

}

.modal {
  

  h4 {
    text-align: center;
    margin-bottom: $margin-md
  }

  .containerField {
    @include containerField();

  }

  .actions {
    display: flex;
    justify-content: flex-end;
    button:first-child {
      text-decoration: underline;
    }
    button:last-child {
      margin-left: $margin-md;
    }
  }

}