@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.customs {

  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $margin-md;
  }

  .containerField {
    @include containerField();
    &.checkbox {
      flex-direction: column;
      display: flex;
      justify-content: flex-end;
    }
  }

  .custom {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    .row {
      width: 70%;
      &.name {
        grid-template-columns: 1fr;
      }
    }  
    .containerField {
      width: 100%;
      label {
        font-family: "Manrope-Regular";
        margin-bottom: 5px;
        display: block;
      }
      input {
        width: 100%;
        background-color: #F4F7FE;
        border: solid 1px #F4F7FE;
        border-radius: 4px;
        padding: 11px;
        font-family: "Manrope-Regular";
        &:focus {
          outline: solid 1px $secondary-color;
        }
      }
    }
    .remove {
      top: 20px;
      right: 0px;
      position: absolute;
      background-color: transparent;
      color: rgba($secondary-color, 0.4);
      padding: 0px;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Manrope-Regular";
      svg {
        font-size: 16px;
        margin-left: 5px;;
      }
      &:hover {
        color: $warn;
      }
    }
  }

  .add {
    margin-top: 10px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='11' ry='11' stroke='%23B8BDCBFF' stroke-width='3' stroke-dasharray='4%2c12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 11px;
    background-color: transparent;
    width: 100%;
    height: 50px;
    color: $secondary-color;
  }
}
