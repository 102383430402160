@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.infos {
  width: 100%;
  padding: 30px;
  margin: auto;
  max-width: 1440px;
  .title {
    display: flex;
    margin-bottom: 10px;
    span {
      font-size: 18px;
      margin: 5px 0px 0px 10px;
      cursor: pointer;
    }
  }
  display: flex;
  justify-content: space-between;
  main {
    width: 60%;
    section {
      padding: 0px 0px;
      &:nth-child(3) {
        hr {
          margin: 20px 0px 15px 0px;
        }
      }
      h4 {
        margin-bottom: 15px;
      }
      .field {
        @include containerField;
      }
      hr {
        margin: 0px 0px 15px 0px;
      }
      .row {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: $margin-md;
      }
    }
  }
  aside {
    max-width: 460px;
    width: calc(40% - 40px);
  }
}