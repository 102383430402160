@import '../../../styles/_variables.scss';

.tooltipContent {
  img {
    height: 50px;
    width: 150px;
    object-fit: contain;
  }
}

.pricingTable {
  table, th, td {
    border: 1px solid $grey;
    border-collapse: collapse;
  }

  th, td {
    padding: $margin-xs $margin-sm;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;

    thead {
      th {
        position: relative;
        text-align: left;
        cursor: pointer;
        background-color: $light-grey;

        p {
          position: relative;
          font-family: 'Manrope-SemiBold';
          color: $text-color;
          margin: 0;
          svg {
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
          }
  
          .sortIcons {

            >svg{
              opacity: 0.3;
            }

            &.asc {
              >svg:first-child {
                opacity: 1;
              }
            }

            &.desc >svg:nth-child(2){
              opacity: 1;
            }


            >svg:first-child {
              transform: translateY(-80%);
            }
            >svg:nth-child(2) {
              transform: translateY(-20%);
            }
          }
        }

        .tooltip {
          position: absolute;
          top: 3px;
          right: 3px;
        }

      }
    }

    tbody {
      tr {
        cursor: pointer;
      }
      
      td {
        font-family: 'Manrope-Regular';
        background-color: $white;
        &:first-child {
          font-family: 'Manrope-SemiBold';
          color: $text-color;
        }

        &:last-child.containerInput {
          background-color: $light-grey;
        }

        &.containerInput {
          padding: 0;
          input {
            border: none;
            padding: $margin-xs $margin-sm;
            width: 100%;
            height: 39px;
            outline: none;
            background-color: transparent;
            &:focus {
              box-shadow: inset 0 0 0 1px $primary-color;
            }
          }
        }


        p.location {
          margin: 0;
          font-family: 'Manrope-SemiBold';
          color: $text-color;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

      }
    }

  }
}