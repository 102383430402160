@import "../../styles/_variables.scss";

.container {
  display: inline-block;
  background-color: $white;
  border-radius: 5px;
  box-shadow: 0px 18px 32px 0px rgba(208, 210, 218, 0.15);
  padding: 0 $margin-xs;
  label {
    display: inline-block;
    font-family: 'Manrope-Regular';
    font-size: $regular;
    color: $text-color;
    margin: 0;
  } 
}