@import "../../../styles/_variables.scss";
@import "../../../styles/mixins";

.infos {
  padding: 20px;
  &.desktop {
    padding: 0px;
  }
  h1 {
    font-size: 18px;
  }
  h2 {
    font-size: 18px;
  }
  .field {
    font-size: 14px;
    margin-bottom: 20px;
    label {
      color: $secondary-color;
    }
    a {
      display: block;
      font-family: "Manrope-Bold";
      text-decoration: underline;
    }
    p {
      font-family: "Manrope-Bold";
      margin: 0px;
    }
  }
}