@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.export {
  padding: 0px 10px;
  h3 {
    text-align: center;
    font-size: 18px;
  }
  ul {
    list-style: none;
    li {
      color: rgba($secondary-color, 0.5);
      @include flex-center;
      justify-content: flex-start;
      margin-bottom: 10px;
      cursor: pointer;
      &:hover {
        p {
          color: rgba($secondary-color, 0.8);
        }
        .check {
          svg {
            color: rgba($secondary-color, 0.8);
          }
        }
      }
      &.active {
        p {
          color: $secondary-color;
        }
        .check {
          background-color: $secondary-color;
          svg {
            color: $white;
          }
        }
      }
      .check {
        min-width: 16px;
        height: 16px;
        border: 1.5px solid rgba($secondary-color, 0.5);
        border-radius: 3px;
        margin-right: 10px;
        position: relative;
        svg {
          position: absolute;
          font-size: 16px;
          left: -1.5px;
          top: -1px;
          color: $white;
        }
      }
      p {
        color: rgba($secondary-color, 0.5);
        margin: 0px;
      }
    }
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 40px;
    .loader {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      p {
        margin: 0px 0px 0px 10px;
      }
    }
    button {
      margin-left: 10px;
    }
    .cancel {
      background-color: transparent;
      text-decoration: underline;
      color: $secondary-color;
    }
    .submit {
      @include flex-center;
      svg {
        font-size: 18px;
        margin-right: 10px;
      }
    }
  }
}