@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.todos {
  .input {
    border-radius: 8px;
    border: 2px dashed #B8BDCB;
    box-shadow: 0px 18px 32px 0px rgba(208, 210, 218, 0.15);
    min-height: 50px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    .check {
      min-width: 18px;
      height: 18px;
      border: 1.5px solid rgba($secondary-color, 0.5);
      border-radius: 3px;
      margin-right: 10px;
      margin-left: 15px;
      position: relative;
      svg {
        position: absolute;
        font-size: 18px;
        left: -1.5px;
        top: -1px;
      }
    }
    input {
      width: calc(100% - 100px);
      padding: 10px 0px;
      background-color: transparent;
      border: none;
      font-family: "Manrope-Regular";
      line-height: 14px;
      &:focus {
        outline: none;
      }
      &::placeholder {
        color: rgba($secondary-color, 0.7);
      }
    }
    .add {
      display: flex;
      align-items: center;
      margin-right: 10px;
      padding: 5px 10px;
      height: auto;
      svg {
        font-size: 20px;
        margin-right: 5px;
      }
    }
  }
  .todo {
    position: relative;
    padding: 0px 15px;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    border: 2px solid $white;
    background-color: $white;
    box-shadow: 0px 18px 32px 0px rgba(208, 210, 218, 0.15);
    margin-bottom: 10px;
    .content {
      display: flex;
      align-items: center;
      p {
        margin: 0px;
        font-size: 13px;
        span {
          color: #6AA187;
          font-family: "Manrope-SemiBold";
          &.late {
            color: #FC8E8F;
          }
        }
      }
      .check {
        min-width: 18px;
        height: 18px;
        border: 1.5px solid #6AA187;
        border-radius: 3px;
        margin-right: 10px;
        position: relative;
        cursor: pointer;
        color: #6AA187;
        svg {
          position: absolute;
          font-size: 18px;
          left: -1.5px;
          top: -1px;
        }
        &.active {
          color: transparent;
          border: 1.5px solid rgba($secondary-color, 0.5);
        }
        &:hover {
          border: 1.5px solid #6AA187;
          color: rgba($secondary-color, 0.5);
          border: 1.5px solid rgba($secondary-color, 0.5);
        }
      }
    }
    .bell {
      background-color: transparent;
      padding: 0px;
      @include flex-center;
    }
    .date-container {
      position: absolute;
      top: 0px;
      padding-top: 50px;
      z-index: 2;
      right: 0px;
      .date {
        padding: 10px;
        background-color: $white;
        box-shadow: 0px 18px 32px 0px rgba(208, 210, 218, 0.15);
        .deleteDate {
          margin-top: 10px;
          border: 1px solid $secondary-color;
          width: 100%;
          color: $secondary-color;
          background-color: transparent;
          padding: 0px;
        }
      }
    }
  }
}