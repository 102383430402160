@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.pagination {
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;
  button {
    padding: 0px 10px;
    min-width: 30px;
    margin-left: 5px;
    height:30px;
    background-color: rgba($secondary-color,0.2);
    color: $secondary-color;
    &.active {
      background-color: $secondary-color;
      color: $white;
    }
  }
}