@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.generalInfos {
  padding-bottom: 30px;
  h1 {
    margin: 0;
  }

  h4 {
    margin-bottom: $margin-sm;
    font-family: "Manrope-Bold";
  }



  section {
    &:first-child {
      margin-bottom: 20px;
    }
    .row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: $margin-md;
    }
    .transport {
      margin-bottom: 20px;
      .transport-details {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 5px ;
        background-color:  #F4F7FE;
        border-radius: 4px;
        .col {
          padding-left: 10px;
          &:first-child {
            p {
              font-family: "Manrope-Bold";
            }
          }
          p {
            margin: 0px;
          }
        }
        button {
          padding: 0px;
          width: 30px;
          height: 30px;
        }
      }
    }
  
    .containerField {
      @include containerField();
      &.discount {
        width: 140px;
      }
      label {
        display: inline-block;
        font-family: "Manrope-Regular", sans-serif;
        font-weight: normal;
        font-size: $regular;
        line-height: 17px; 
        color: $text-color;
        margin: 0px;
      }
      &.checkbox {
        flex-direction: column;
        display: flex;
        justify-content: flex-end;
      }

      &.duration {
        label {
          margin-bottom: $margin-xs;
        }
        .fields {
          display: flex;
        }
      }

    }

    .rate {
      span {
        background-color: #FFF4B7;
        font-family: "Manrope-Bold";
        padding: 3px 7px;
        border-radius: 3px;
      }
      &.discount {
        span {
          &:first-child {
            background-color: transparent;
            text-decoration-line: line-through;
          }
          background-color: rgba($success, 0.3);
        }
      }
    }
  }

  .delete {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: transparent;
    color: $warn;
    display: flex;
    align-items: center;
    font-size: 14px;
    &:hover {
      text-decoration: underline;
    }
    svg {
      margin-right: 6px;
      font-size: 18px;
    }
  }
}