@import "../../styles/_variables.scss";

.layout {
  background-color: $bg-color;
  min-height: calc(100% - 64px);
  height: calc(100% - 64px);

  .global {
    height: 100%;
    overflow-y: auto;
  }

}