
@import "../../../styles/variables";
@import "../../../styles/_mixins.scss";

.form {

  h3 {
    text-align: center;
    margin-bottom: $margin-md;
  }

  .fields {
    margin: $margin-lg 0 $margin-lg 0;
    .containerField {
      @include containerField();
    }
  }


  .buttons {
    display: flex;
    justify-content: flex-end;

    button:first-child {
      text-decoration: underline;
    }

    button:last-child {
      margin-left: $margin-md;
    }
  }
}