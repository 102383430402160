@import "../../../../styles/_variables.scss";
@import "../../../../styles/_mixins.scss";

.noteModal {
  background-color: $white;
  padding: 5px $margin-xs;
  box-shadow: 0px 18px 32px 0px rgba(208, 210, 218, 0.15);
  border-radius: $radius;
  border: 1px solid rgba(113, 122, 151, 0.21);
  p {
    margin: 4px 0;
    line-height: 26px;
    cursor: pointer;

    svg {
      position: relative;
      top: 4px;
      margin-right: 5px;
    }

    &.delete {
      svg {
        fill: $warn;
      }
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

.container .form {

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $margin-sm;
    h4 {
      margin-bottom: 0;
    }

    button {
      width: 40px;
      padding: 0;
    }
  }

  .notes {
    .note {
      display: flex;
      align-items: flex-start;

      background-color: $white;
      border-radius: $radius;
      box-shadow: 0px 18px 32px 0px rgba(208, 210, 218, 0.15);
      padding: $margin-sm;
      margin-bottom: $margin-xs;

      >div:first-child {
        flex: 1;
        .text {
          margin-bottom: 5px;
        }
      }

      .author {
        color: #B8BDCB;
        margin-bottom: $margin-xs;
      }

      .file {
        a {
          text-decoration: underline;
          svg {
            position: relative;
            top: 4px;
            margin-right: 5px;
          }
        }
      }

      >button {
        margin-left: $margin-xs;
        cursor: pointer;
        height: 19px;
        color: #B8BDCB;
      }

    }
  }
}