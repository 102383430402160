@import "../../../styles/_variables.scss";
@import "../_form-mixin.scss";

.checkbox {
  &.inline {
    display: flex;
    align-items: center;
    .container {
      margin-right: $margin-xs;
    }
  }
  &.disabled {
    pointer-events: none;
  }
  label {
    @include label;
    cursor: pointer;
    margin-bottom: 0;

  }

  .container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 45px;
  }

  .square {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background-color: $white;
    border: solid 3px $text-color;
    cursor: pointer;
    >svg {
      position: absolute;
      width: 20px;
      height: 20px;
      color: $text-color;
    }
  }
}