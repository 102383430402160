@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.requests {
  padding: 20px;
  padding-bottom: 120px;
  h1 {
    font-size: 18px;
  }
  .new {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    svg {
      font-size: 20px;
      margin-right: 8px;
    }
  }
  h2 {
    font-size: 14px;
    padding-bottom: 6px;
    border-bottom: 1px solid #B8BDCB;
  }
  .select {
    display: flex;
    flex-direction: column;
    a {
      border-radius: 8px;
      border: 1px solid $secondary-color;
      height: 70px;
      background-color: $white;
      box-shadow: 0px 18px 32px 0px rgba(208, 210, 218, 0.15);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      svg {
        margin-right: 30px;
      }
      p {
        margin: 0px;
        width: 200px;
        font-size: 16px;
        font-family: "Manrope-Bold";
        text-align: left;
      }
    }
  }
  .list {
    display: flex;
    flex-direction: column;
    button {
      display: flex;
      align-items: center;
      background-color: $white;
      text-align: left;
      border-radius: 8px;
      border: 1px solid rgba(163, 174, 208, 0.40);
      padding: 10px 20px;
      height: auto;
      margin-bottom: 10px;
      p {
        margin: 0px;
      }
      .infos {
        display: block;
        margin-left: 20px;
        p {
           &:first-child {
            font-family: "Manrope-Bold";
           }
           &:last-child {
            color: #717A97;
            font-size: 12px;
           }
        }
      }
    }
  }
}