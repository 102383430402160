@import '../../../styles/variables.scss';
@import '../form-mixin.scss';

.list-tags{
  width: 100%;
  label {
    @include label;
  }

  &.inline{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    label{
      margin-right: 10px;
      min-width: 125px;
    }
  }

  &:not(.inline){
    label+ul{
      margin-top: $margin-sm;
    }
  }

  ul{
    padding: 0;
    margin: 0;
    li{
      display: inline-block;
      list-style: none;
      margin: 0 $margin-sm $margin-sm 0;
      button {
        font-family: "Manrope-Bold";
        border: none;
        color: $text-color-light;
        background-color: transparent;
        border-radius: 4px;
        box-shadow: inset 0 0 0 1px #DBDBDB;
        padding: 10px;
        min-width: 60px;
      }
      button.selected {
        background-color: $secondary-color;
        border-color: $secondary-color;
        color: $white;
        box-shadow: inset 0 0 0 1px $secondary-color;
      }
    }
  }
}
