
@import './_variables.scss';

@mixin page-auth {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  background: #1B2559;
  padding: $margin-md $margin-sm;
  @media screen and (min-width: $xs-screen) {
    background: $bg-color;
  }

  @media screen and (min-width: $md-screen) {
    align-items: center;
    padding: 0 $margin-sm;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    @media screen and (min-width: $md-screen) {
      width: auto;
    }
    .picture {
      padding: $margin-md;
      img {
        max-height: 200px;
        width: 100%;
        max-width: 500px;
        @media screen and (min-height: 700px) {
          max-height: 100%;
        }
      }
    }
    
    .containerForm {
      width: calc(100% - 20px);
      @media screen and (min-width: $md-screen) {
        width: auto;
      }
      >h1 {
        @include h3();
        font-family: 'Manrope-Light';
        text-align: center;
        margin-bottom: $margin-md;
        padding: 0 $margin-lg;
        display: none;
        @media screen and (min-width: $md-screen) {
          display: block;
        }
      }

      .form {
        width: 100%;
        background-color: $white;
        border-radius: 8px;
        border: solid 1px $border-grey;
        padding: $margin-lg;
      }
  
    }

    @media screen and (min-width: $md-screen) {
      flex-direction: row;

      .containerForm {
        margin: 0px $margin-xl;
        .form {
          width: 450px;
        }
      }

    }

    @media screen and (min-width: $lg-screen) {
      flex-direction: row;

      .containerForm {
        margin: 0px $margin-xl;
        .form {
          width: 500px;
        }
      }

    }
    
  }
}