@import '../../../styles/_variables.scss';

.clientsTable {
  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;

    thead {
      th {
        text-align: left;
        padding: 0 $margin-md 5px $margin-md;
        cursor: pointer;

        p {
          font-family: 'Manrope-Light';
          font-size: $small;
          color: $text-color-light;
          display: inline-block;
          position: relative;
          padding-right: $margin-md;
          margin: 0;
          svg {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
          }
  
          .sortIcons {
            opacity: 0.3;
            >svg:first-child {
              transform: translateY(-80%);
            }
            >svg:nth-child(2) {
              transform: translateY(-20%);
            }
          }
        }

      }
    }

    tbody {
      tr {
        cursor: pointer;
      }
      
      td {
        padding: 5px 0;

        &:first-child .cell {
          border-radius: $radius 0 0 $radius;
        }
        &:last-child .cell {
          border-radius: 0 $radius $radius 0;
        }

        .cell {
          padding: $margin-xs $margin-md;
          background-color: $white;
          box-shadow: 0px 18px 32px 0px rgba(208, 210, 218, 0.15);

          &.name .content {
            font-family: 'Manrope-SemiBold';
          }

          .content {
            padding: 5px 0;
            min-height: 26px;
          }

        }
      }
    }

  }
}