@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.comments-container {
  padding: 0px 0px 0px 30px;
  width: 100%;
  h4 {
    margin-bottom: 20px;
  }
  .form {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    border-radius: 10px;
    background: $white;
    box-shadow: 0px 18px 32px 0px rgba(208, 210, 218, 0.15);
    padding: 10px;
    .input {
      width: calc(100% - 155px);
      textarea {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        font-family: "Manrope-Regular";
        font-size: 14px;
        border: 1px solid transparent;
        color: $secondary-color;
        &::placeholder {
          color: #717A97;
        }
        &:focus {
          outline: none;
        }      }
      p {
        margin: 0px;
        span {
          cursor: pointer;
          svg {
            color: $warn;
            transform: translateY(6px);
          }
        }
      }
    }
    .actions {
      display: flex;
      align-items: flex-start;
      label {
        margin: 0px;
        background-color: $secondary-color;
        color: $white;
        width: 40px;
        height: 40px;
        margin-right: 10px;
        @include flex-center;
        border-radius: 5px;
      }
    }
    .file {
      display: none;
    }
  }
  .comments {
    .comment {
      width: calc(100% - 50px);
      border-radius: 10px;
      background: $white;
      box-shadow: 0px 18px 32px 0px rgba(208, 210, 218, 0.15);
      padding: 10px 15px;
      margin-bottom: 15px;
      &.me {
        margin-left: 50px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        p {
          text-align: right;
        }
        .author {
          flex-direction: row-reverse;
          p {
            &.date {
              margin-right: 10px;
            }
          }
        }
      }
      .profile {
        width: 33px;
        height: 33px;
        background-color: $secondary-color;
        border-radius: 20px;
        color: $white;
        margin-right: 11px;
        @include flex-center;
      }
      p {
        font-size: 13px;
      }
      .text {
        padding-left: 45px;
      }
      .author {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        p {
          margin: 0px;
          &.date {
            margin-left: 10px;
            color: #B8BDCB;
          }
        }
      }
      .file {
        padding-left: 45px;
        a {
          font-size: 13px;
          display: flex;
          align-items: center;
          text-decoration: underline;
          cursor: pointer;
          svg {
            margin-right: 5px;
          }
        }
      }
    }
  }
}