@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.phases {
  width: 100%;
  padding: 30px;
  margin: auto;
  max-width: 1440px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  .col {
    .add {
      display: flex;
      align-items: center;
      padding: 0 15px 0px 10px;
      margin-bottom: 20px;
      svg {
        font-size: 20px;
        margin-right: 8px;
      }
    }
    h4 {
      font-size: 16px;
    }
    .phases-list {
      margin-top: 10px;
      margin-bottom: 20px;
      &:first-child {
        margin-top: 20px;
      }
      .phase {
        @include transition;
        border-radius: 8px;
        border: 1px solid rgba(163, 174, 208, 0.40);
        box-shadow: 0px 18px 32px rgba(208, 210, 218, 0.15);
        padding:  12px 15px;
        margin-bottom: 10px;
        cursor: pointer;
        .title {
          display: flex;
          justify-content: space-between;
        }
        h4 {
          font-size: 16px;
          margin: 0px;
        }
        p {
          margin: 0px;
          font-size: 13px;
          color: $text-color-light;
        }
        &.active {
          background-color: $white;
        }
      }
    }
  }
}