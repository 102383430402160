@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.form {
  position: relative;
  padding-bottom: 45px;
  h4 {
    margin-bottom: $margin-sm;
  }

  .containerField {
    @include containerField();
    &.name {
      input {
        padding: 0 0 5px 0;
        background-color: transparent;
        font-size: $h3;
        border: none;
        border-radius: 0;
        border-bottom: solid 1px $border-grey;
      }
    }
  }
  .delete {
    position: absolute;
    bottom: -10px;
    right: -20px;
    background-color: transparent;
    color: $warn;
    display: flex;
    align-items: center;
    font-size: 14px;
    &:hover {
      text-decoration: underline;
    }
    svg {
      margin-right: 6px;
      font-size: 18px;
    }
  }
}
