@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.ged {
  width: 100%;
  padding: 20px;
  margin: auto;
  max-width: 1440px;
  @media screen and (min-width: $md-screen) {
    padding: 30px;
  }
  .title-desktop {
    display: none;
    justify-content: flex-start;
    @media screen and (min-width: $md-screen) {
      display: flex;
    }
    .add {
      display: flex;
      align-items: center;
      label {
        font-family: "Manrope-SemiBold";
        color: $white;
        font-size: $regular;
        border: none;
        background-color: $secondary-color;
        outline: inherit;
        box-shadow: none;
        padding: 0 15px 0px 10px;
        height: 40px;
        border-radius: $radius;
        display: block;
        margin: 0px;
        @include flex-center;
        svg {
          font-size: 20px;
          margin-right: 8px;
        }
      }
      input {
        display: none;
      }
      p {
        margin: 0px 15px;
      }
    }
  }
  .title-mobile {
    display: flex;
    justify-content: space-between;
    h1 {
      font-size: 18px;
      margin-bottom: 0px;
    }
    h2 {
      font-size: 14px;
    }
    input {
      display: none;
    }
    label {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $text-color;
      color: $white;
      font-size: 20px;
      border-radius: 5px;
    }
    @media screen and (min-width: $md-screen) {
      display: none;
    }
  }
  .files {
    margin-top: 20px;
    .row {
      display: flex;
      align-items: center;
      border-radius: 8px;
      background-color: $white;
      height: 60px;
      box-shadow: 0px 18px 32px 0px rgba(208, 210, 218, 0.15);
      margin-bottom: 10px;
      .col {
        padding: 0px 18px;
        width: calc(60% / 3);
        &:first-child {
          width: 40%;
          display: flex;
          align-items: center;
          .picture {
            width: 40px;
            height: 40px;
            transform: translateX(-5px);
            margin-right: 5px;
            overflow: hidden;
            border-radius: 5px;
            border: 1px solid rgba($secondary-color, 0.5);
            @include flex-center;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
        &:last-child {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          svg {
            font-size: 20px;
          }
          a {
            opacity: 0.3;
            @include transition;
            cursor: pointer;
            &:hover {
              opacity: 1;
            }
          }
          button {
            @include transition;
            background-color: transparent;
            color: $secondary-color;
            padding: 0px;
            margin-left: 8px;
            opacity: 0.3;
            &:hover {
              color: $warn;
              opacity: 1;
            }
          }
        }
        p {
          margin: 0px;
        }
      }
    }
    .card {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 8px;
      background-color: $white;
      box-shadow: 0px 18px 32px 0px rgba(208, 210, 218, 0.15);
      margin-bottom: 10px;
      padding: 10px;
      .infos {
        display: flex;
        align-items: center;
        .picture {
          width: 55px;
          height: 55px;
          margin-right: 15px;
          overflow: hidden;
          border-radius: 5px;
          border: 1px solid rgba($secondary-color, 0.5);
          @include flex-center;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .title {
          h3 {
            font-size: 13px;
            margin-bottom: 5px;
          }
          p {
            font-size: 10px;
            margin: 0px;
            color: $secondary-color;
            opacity: 0.5;
          }
        }
      }
      .actions {
        margin-right: 4px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        svg {
          font-size: 20px;
          color: $secondary-color;
          opacity: 0.5;
        }
        button {
          margin-top: 10px;
          padding: 0px;
          height: auto;
          background-color: transparent;
        }       
      }
    }
  }
}