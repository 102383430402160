@import '../../../styles/_variables.scss';

.worksitesTable {
  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;

    thead {
      th {
        text-align: left;
        padding: 0 $margin-md 5px $margin-md;
        cursor: pointer;

        p {
          font-family: 'Manrope-Light';
          font-size: $small;
          color: $text-color-light;
          display: inline-block;
          position: relative;
          padding-right: $margin-md;
          margin: 0;
          svg {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
          }
  
          .sortIcons {
            opacity: 0.3;
            >svg:first-child {
              transform: translateY(-80%);
            }
            >svg:nth-child(2) {
              transform: translateY(-20%);
            }
          }
        }

      }
    }

    tbody {
      tr {
        cursor: pointer;
      }
      
      td {
        padding: 5px 0;
        width: calc(40% / 2);
        
        &:first-child .cell {
          border-radius: $radius 0 0 $radius;
        }
        &:last-child .cell {
          border-radius: 0 $radius $radius 0;
        }

        &:nth-child(1),
        &:nth-child(2) {
          .cell .content {
            white-space: nowrap;
          }
        }
        &:nth-child(2) {
          width: 15%;
        }

        &:nth-child(4) {
          width: 5%;
        }

        &:nth-child(3),
        &:nth-child(5) {
          width: 10%;
        }

        &:last-child {
          width: 18%;
        }

        .cell {
          background-color: $white;
          padding: 0px $margin-md;
          background-color: $white;
          display: flex;
          align-items: center;
          box-shadow: 0px 18px 32px 0px rgba(208, 210, 218, 0.15);
          min-height: 46px;

          .content {
            padding: 5px 0;
            overflow: hidden;
          }

          &.status {
            .dot {
              position: relative;
              top: 1px;
              display: inline-block;
              width: 12px;
              height: 12px;
              border-radius: 6px;
              background-color: $primary-color;
              margin: 0 8px 0 0px;
            }
          } 


        }
      }
    }

  }
}