@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.comments {
  width: 100%;
  padding: 30px;
  margin: auto;
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
  .col {
    width: 55%;
    &:first-child {
      width: calc(45% - 30px);
    }
    &:last-child {
      min-height: calc(100vh - 230px);
      border-left: 1px solid rgba(#B8BDCB, 0.5);
    }
    .notes {
      margin: auto;
      max-width: 500px;
    }
  }
}