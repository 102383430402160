@import '~/styles/_variables.scss';
@import '~/styles/_mixins.scss';

.content {
    @include content();
}

.listClients {
    .header {
        margin-bottom: $margin-md;
        .filters {
          display: flex;
          justify-content: space-between;
          .selects {
            display: flex;
            .field {
                margin-right: $margin-sm;
            }
          }
          .right {
            display: flex;
            button {
              margin-left: $margin-sm
            }
          }
          .search {
            margin-left: auto;
          }
        }
    }
}