@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.dimension{

  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $margin-md;
    .tonnage {
      span {
        background-color: #FFF4B7;
        font-family: "Manrope-Bold";
        padding: 3px 7px;
        border-radius: 3px;
      }
    }
  }

  .containerField {
    @include containerField();
    &.checkbox {
      flex-direction: column;
      display: flex;
      justify-content: flex-end;
    }
  }
}
