@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.home {
  width: 100%;
  .content {
    @include content();
    @media screen and (min-width: $md-screen) {
      margin-top: 40px;
    }
    h2 {
      font-size: 18px;
      padding-bottom: 8px;
      border-bottom: 1px solid #B8BDCB;
    }
    .row {
      @media screen and (min-width: $md-screen) {
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        .col {
          width: calc(340px);
          &:first-child {
            width: calc(100% - 370px);
          }
        }
      }
      a {
        display: block;
        cursor: pointer;
        border-radius: 8px;
        background: $white;
        box-shadow: 0px 18px 32px 0px rgba(208, 210, 218, 0.15);
        @include transition;
        &:hover {
          box-shadow: 0px 18px 32px 0px rgba(208, 210, 218, 0.5);
        }
        padding: 15px 15px;
        h3 {
          margin-bottom: 2px;
          font-size: 18px;
        }
        p {
        color: #717A97;
        margin: 0px; 
        } 
      }
      .worksites {
        display: grid;
        margin-bottom: 20px;
        gap: 20px;
        @media screen and (min-width: $lg-screen) {
          grid-template-columns: 1fr 1fr;
        }
        @media screen and (min-width: $xl-screen) {
          grid-template-columns: 1fr 1fr 1fr;
        }
        
      }
      .documents {
        display: grid;
        margin-bottom: 20px;
        gap: 20px;
      }
    }
  }
}