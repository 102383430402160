@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

.worksites {
  .content {
    @include content();
    .filters {
      display: flex;
      align-items: center;
      margin-bottom: $margin-md;
      .selects {
        display: flex;
        align-items: center;
        margin-right: auto;
        .field {
          margin-right: $margin-sm;
        }
      }

      button {
        margin-left: $margin-sm
      }

    }
  }
}