
@import './_variables.scss';

@mixin h1 {
  font-size: $h1;
  font-family: 'Manrope-Bold';
  color: $text-color;
}

@mixin h2 {
  font-size: $h2;
  font-family: 'Manrope-Bold';
  color: $text-color;
}

@mixin h3 {
  font-size: $h3;
  font-family: 'Manrope-Bold';
  color: $text-color;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin content {
  max-width: 1440px;
  width: 100%;
  padding: $margin-sm;
  margin: 0 auto;

  @media screen and (min-width: $xs-screen) {
    padding: $margin-md;
  }

  @media screen and (min-width: $sm-screen) {
    padding: $margin-lg;
  }
}


@mixin transition {
  transition: all 0.2s ease-in-out;
}

@mixin tag($style: '') {
  font-family: 'Manrope-SemiBold';
  display: inline-block;
  color : white;
  font-size: $regular;
  background-color: rgba(106, 161, 135, 0.3);
  padding: 6px $margin-xs;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;

  &:not(:last-child){
    margin-right: 5px;
  }

  @if $style == primary {
    background-color: $primary-color;
  }@if $style == light-primary {
    background-color: $primary-color;
    background-color: rgba(106, 161, 135, 0.3);
  } @else if $style == warn {
    background-color: $warn;
  } @else if $style == basic {
    color : $text-color;
    background-color: $light-grey;
  }


  &.selected{
    background-color: $primary-color;
  }

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

@mixin remove-highlight(){
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin remove-tap-highlight(){
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

@mixin containerField {
  margin-bottom: $margin-md;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba($black, 0.5);
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: $black;
  }
}

@mixin remove-highlight() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin remove-tap-highlight() {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}