@import "../../../../styles/_variables.scss";
@import "../../../../styles/_mixins.scss";

.saleForm {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $margin-sm;
  width: 100%;

  .form {
    box-shadow: 0px 18px 32px 0px rgba(208, 210, 218, 0.15);
    border-radius: $radius;
    background-color: $white;
    padding: $margin-md;
  }

  h4 {
    margin-bottom: $margin-sm;
  }
  
  .containerField {
    @include containerField();
  }
}