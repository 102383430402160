@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.worksiteDesktop {
  width: 100%;
  position: relative;
  .content {
    position: relative;
    padding-top: 100px;
  }
}