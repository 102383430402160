@import './_mixins.scss';
@import './_variables.scss';

body {
  margin: 0;
  font-family: "Manrope-Regular";
  font-size: $regular;
  background-color: $bg-color;
  color: $text-color;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  font-family: sans-serif;
}

html, body, #root, .App {
  min-height: 100%;
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

h1 {
  @include h1();
  margin: 0 0 $margin-sm 0;
}

h2 {
  @include h2();
  margin: 0 0 $margin-sm 0;
}

h3 {
  @include h3();
  margin: 0 0 $margin-sm 0;
}

h4 {
  font-family: 'Manrope-SemiBold';
  font-weight: normal;
  font-size: $medium;
  color: $text-color;
}

label,
p {
  font-size: $regular;
  color: $text-color;
  margin: 0 0 $margin-sm 0;
  &.small {
    font-size: $small;
  }
}

p {
  font-family: "Manrope-Regular";
}

a, li {
  font-size: $regular;
  font-family: "Manrope-Regular";
  color: $text-color;
}

hr {
  margin: $margin-sm 0 $margin-md 0;
  border-color: rgba(#B8BDCB, 0.5);
  border-width: 0.5px;
}

button {
  font-family: "Manrope-SemiBold";
  color: $white;
  font-size: $regular;
  border: none;
  background-color: $secondary-color;
  outline: inherit;
  box-shadow: none;
  padding: 0 $margin-md;
  height: 40px;
  border-radius: $radius;
  
  .icon {
    position: relative;
    top: 1px;
    left: -1px;
    vertical-align: middle;
    font-size: 18px;
    margin-right: 5px;
  }


  &:not(:disabled) {
    cursor: pointer;
  }

  &:disabled {
    background-color: $grey;
  }

  &.secondary {
    border: solid 1px $secondary-color;
    background-color: $white;
    color: $text-color;
  }

  &.invisible {
    padding: 0;
    border: none;
    background-color: transparent;
    color: $text-color;
  }

}

.Toastify {
  .Toastify__toast--error {
    background-color: red;
    .Toastify--animate-icon svg {
      fill: white;
    }
  }
  // div[class$='__toast--error'] {
  //   background-color: red;
  // }
}

.rdp-root {
  --rdp-accent-color: $primary-color;
  --rdp-day-height: 2rem;
  --rdp-day-width: 2rem;
}

.rdp-chevron {
  display: inline-block;
  fill: $primary-color;
}

.rdp-day_button:disabled {
  cursor: not-allowed;
  background-color: transparent;
}

.rdp-selected {
  .rdp-day_button {
    border: $primary-color;
  }
}